import React from 'react';
import Featurebox from '../Accueil/Featurebox';
import fimage1 from '../../images/SVG/karate-1.svg';
import fimage2 from '../../images/SVG/kick-1.svg';
import fimage3 from '../../images/SVG/punch-1.svg';
import fimage4 from '../../images/SVG/ladies-1.svg';

const messageKarate= "Le karaté est né à Okinawa, une île du Japon, au début du 20e siècle. Il s'est développé à partir des arts martiaux indigènes d'Okinawa, influencés par les techniques de combat chinoises, notamment celles du Kung Fu. "; 

const messageKick="Le K-1 est une organisation fondée en 1993 par Kazuyoshi Ishii, un ancien pratiquant de karaté Seidokaikan. K-1 est connu pour ses compétitions de kickboxing, qui combinent des éléments de plusieurs style, notamment le karaté, le muay-thaï, le taekwondo, le kung-fu et le sanda.";

const messageMMA="Le MMA (Mixed Martial Arts) trouve ses racines dans plusieurs traditions de combat anciennes, mais sa naissance moderne peut être tracée à quelques événements et influences clés au 20ème siècle notamment le pankration, Vale Tudo";

const messageLadies="Spécialement conçue pour les femmes qui souhaitent s'initier ou se perfectionner dans les arts martiaux et les sports de combat dans un environnement accueillant et motivant. Que vous soyez débutante ou pratiquante expérimentée, nos séances sont adaptées pour répondre à tous les niveaux de compétence.";


function Feature() {
  return (
    
    <div id='features'>
        <h1>LES DISCIPLINES</h1>
        <div className='a-container'>
            <Featurebox image={fimage1} title="Karaté" text={messageKarate} />
            <Featurebox image={fimage2} title="Kickboxing" text={messageKick}/>
            <Featurebox image={fimage3} title="MMA Budo" text={messageMMA}/>
            <Featurebox image={fimage4} title="Ladies Fight" text={messageLadies}/>
        </div>
    </div>
    
  )
}

export default Feature
