import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';

const SocialLinks = () => {
  return (
    <ul className="team-icon">
      <li>
        <a href="https://www.instagram.com/hobukan_gennevilliers/" target="_blank" className="instagram">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/Hobukan.Gennevilliers/?locale=fr_FR" target="_blank" className="facebook">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UC3MQtCpUYV06lu3JZGpiqNQ/videos" target="_blank" className="youtube">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </li>
      <li>
        <a href="#" className="twitter">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;