import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Style from "./Video.css";

function Video() {
  return (
    <div>
      <Navbar />;
      <div className="saison-list">
        <div className="saison-22-23">
          <h2>saison 2022 2023</h2>
          <div className="video-list">
            <div className="video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/NiP65LnO4as?si=GTyKTzZxBmAxoFva"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>

            <div className="video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/1sFSU2VBO9s?si=H6sM6I8WOhjp2fWw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>

            <div className="video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/sDA8hnFQbVY?si=BQFAF6Et0BgOr4wO"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>

        <div className="saison-21-22">
          <h2>saison 2021 2022</h2>
          <div className="video-list">
            <div className="video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/zk-VwVsSAlM?si=9lN61mmpL8UHD0Yr"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>

            <div className="video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/dRm6NpX1LYU?si=Qnh9jBELhR4h3P6l"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>

            <div className="video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/NWINMqqeiXM?si=yItQxgMF0HWmcAy0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />;
    </div>
  );
}

export default Video;
