import React from 'react';
import ReactPlayer from 'react-player/youtube';

const VideoComponent = () => {
  return (
    <div>
      <ReactPlayer
        url="https://youtu.be/ucZpqP8OFCM?si=Y16aSIXo4kbZSduG"
        playing={true} 
        muted={true} 
        controls={true}
      />
    </div>
  );
}

export default VideoComponent;