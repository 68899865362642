import "./Image.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";

import Image1 from "./Gallery2024/Gallery1.jpeg";
import Image2 from "./Gallery2024/Gallery2.jpeg";
import Image3 from "./Gallery2024/Gallery3.jpeg";
import Image4 from "./Gallery2024/Gallery4.jpeg";
import Image5 from "./Gallery2024/Gallery5.jpeg";

export default function App() {
  const images = [
    {
      original: Image1,
      thumbnail: Image1,
    },
    {
      original: Image2,
      thumbnail: Image2,
    },
    {
      original: Image3,
      thumbnail: Image3,
    },
    {
      original: Image4,
      thumbnail: Image4,
    },
    {
      original: Image5,
      thumbnail: Image5,
    },
  ];

  return (
    <div className="App">
      <div>
        <Navbar />
      </div>
      <br></br>
      <h1></h1>
      <h2>Vous trouverez ici les moments marquants de la saison 2023-2024</h2>
      <ImageGallery
        items={images}
        showPlayButton={false}
        showFullscreenButton={true}
        slideInterval={1000}
        slideOnThumbnailOver={true}
        showIndex={true}
        onPlay={() => {
          alert("slideshow is now playing!");
        }}
      />
      <div>
        <Footer />
      </div>
    </div>
  );
}
