import React from "react";
import Equipe from "../1-Club/Equipe.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faYoutube,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import imageYoshida from "../../images/les membres/Yoshida.JPG";
import imageMohamed from "../../images/les membres/Mohamed.JPG";
import imageMorad from "../../images/les membres/Morad.JPG";
import imageRayane from "../../images/les membres/Rayane.JPG";
import imageYanis from "../../images/les membres/Yanis.JPEG";
import imageMohamedA from "../../images/les membres/MohamedA.JPG";
import imageZaineb from "../../images/les membres/Zaineb.JPG";
import imageYounes from "../../images/les membres/Younes.JPEG";
import imageSamy from "../../images/les membres/Samy.jpg";
import imageNathan from "../../images/les membres/Nathan.JPG";

import Navbar from "../../Components/Navbar";
import SocialLinks from "../../Components/Equipe/SocialLinks";
import Footer from "../../Components/Footer";

function EquipeComponent() {
  let messageKancho = `Retrouvez ici le fondateur de l'école HOBUKAN`;
  let message1 = `Retrouvez ici tous les fondateurs du club HOBUKAN GENNEVILLIERS.`;
  let message2 = `Retrouvez ici les professeurs et assistant du club HOBUKAN GENNEVILLIERS.`;
  let message3 = `Retrouvez ici l'équipe administrative du club HOBUKAN GENNEVILLIERS.`;

  return (
    <div>
      <div>
        <Navbar />
      </div>

      <div className="general">
        <section className="section-black">
          <div className="col text-center">
            <h2 className="section-title">LE KANCHO</h2>
            <p className="section-subtitle">{messageKancho}</p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="team-item">
                  <img
                    src={imageYoshida}
                    className="team-img"
                    alt="Tsunemistu Yoshida"
                  />
                  <h3>TSUNEMITSU KANCHO</h3>
                  <div className="team-info">
                    <p>
                      <strong>Fondateur du Hobukan</strong>
                    </p>
                    <p>
                      <strong>Professeur (Karaté, Kickboxing, MMA)</strong>
                    </p>
                  </div>
                  <p>
                    Kancho Tsunemitsu Yoshida est le fondateur de l'école
                    Hobukan au Japon, il est 5ième dan de karaté Goju-Ryu
                    Hobukan et préparateur physique pour des professionnelles du
                    Kickboxing et MMA au Japon.
                  </p>
                  <SocialLinks />
                </div>
              </div>
            </div>
          </div>
          //SENSEI
          <div className="col text-center">
            <h2 className="section-title">LES SENSEïS</h2>
            <p className="section-subtitle">{message1}</p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="team-item">
                  <img
                    src={imageMohamed}
                    className="team-img"
                    alt="Mohamed Senseï"
                  />
                  <h3>MOHAMED SENSEI</h3>
                  <div className="team-info">
                    <p>
                      <strong>Fondateur</strong>
                    </p>
                    <p>
                      <strong>Professeur de Karaté et Kickboxing(K-1)</strong>
                    </p>
                  </div>
                  <p>
                    Senseï Mohamed est le fondateur de et directeur technique du
                    club, 4ième Dan de karaté, il pratique les arts martiaux
                    depuis plus de 25. Responsable national de l'école Hobukan.
                  </p>
                  <p>Diplôme : STAPS, DEJEPS, BMF, DIF</p>
                  <SocialLinks />
                </div>
              </div>

              <div className="col">
                <div className="team-item">
                  <img
                    src={imageMorad}
                    className="team-img"
                    alt="Morad Senseï"
                  />
                  <h3>MORAD SENSEI</h3>
                  <div className="team-info">
                    <p>
                      <strong>Co-fondateur</strong>
                    </p>
                    <p>
                      <strong> Professeur de Full Contact</strong>
                    </p>
                  </div>
                  <p>
                    Sempai Morad est le co-fondateur du club Hobukan, 3ième dan
                    de karaté il début sa pratique des arts martiaux par le Full
                    Contact dans le club de la famille Kitoko.
                  </p>
                  <p>Diplôme : DIF</p>
                  <SocialLinks />
                </div>
              </div>
            </div>
          </div>
          //LES SEMPAIS
          <div className="col text-center">
            <h2 className="section-title">LES SEMPAïS</h2>
            <p className="section-subtitle">{message1}</p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="team-item">
                  <img
                    src={imageYanis}
                    className="team-img"
                    alt="Yanis Sempaï"
                  />
                  <h3>YANIS SEMPAI</h3>
                  <div className="team-info">
                    <p>Professeur de Karaté et Kickboxing</p>
                  </div>
                  <p>
                    1er dan de Karaté, il a remporté plusieurs fois le titre de
                    champion de France de karaté contact et full contact.
                  </p>
                  <p>Diplôme: STAPS</p>
                  <br></br>
                  <SocialLinks />
                </div>
              </div>

              <div className="col">
                <div className="team-item">
                  <img
                    src={imageRayane}
                    className="team-img"
                    alt="Rayane Sempaï"
                  />
                  <h3>RAYANE SEMPAI</h3>
                  <div className="team-info">
                    <p>Professeur de Karaté et Kickboxing</p>
                  </div>
                  <p>
                    1er dan de Karaté et champion de France de karaté full
                    contact sur la saison 2023-2024. Rayane encadre les ados de
                    11 à 15 ans sur la section kickboxing.
                  </p>
                  <p>Diplôme : BPJEPS et DIF</p>
                  <SocialLinks />
                </div>
              </div>

              <div className="col">
                <div className="team-item">
                  <img
                    src={imageMohamedA}
                    className="team-img"
                    alt="Elisa Johanson"
                  />
                  <h3>CEDRIC SEMPAI</h3>
                  <div className="team-info">
                    <p>Moniteur de Karaté</p>
                  </div>
                  <p>
                    Cédric pratique le karaté traditionnel depuis plusieurs
                    années, il posède également des connaissance dans la patique
                    du Penchak silat.
                  </p>
                  <p>Diplôme : DIF </p>
                  <SocialLinks />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="team-item">
                  <img src={imageYounes} className="team-img" alt="Younes" />
                  <h3>YOUNES SEMPAI</h3>
                  <div className="team-info">
                    <p>Moniteur de Karaté et Kickboxing</p>
                  </div>
                  <p>
                    Younes est arrivé au dojo à l'age de 8 ans, aujourd'hui du
                    hauts de ses 18 ans il à remporté plus de 9 fois le titre de
                    champion de France
                  </p>
                  <p>Diplôme : DIF en cours</p>
                  <SocialLinks />
                </div>
              </div>

              <div className="col">
                <div className="team-item">
                  <img
                    src={imageZaineb}
                    className="team-img"
                    alt="Zaineb Sempaï"
                  />
                  <h3>ZAINEB SEMPAI</h3>
                  <div className="team-info">
                    <p>Moniteur de Kickboxing</p>
                  </div>
                  <p>
                    Championne de France de Karaté et championne du monde de
                    karaté, championne de France de Kickboxing (classe A), 3ième
                    au championnat du monde de MMA Budo au Japon en 2023.
                  </p>
                  <p>Diplôme : DIF en cours</p>
                  <SocialLinks />
                </div>
              </div>

              <div className="col">
                <div className="team-item">
                  <img src={imageSamy} className="team-img" alt="Samy" />
                  <h3>SAMY SEMPAI</h3>
                  <div className="team-info">
                    <p>Professeur MMA</p>
                  </div>
                  <p>
                    Champion de France de grappling et champion de France de
                    Karaté-MIX (version MMA du Karaté).
                  </p>
                  <p>Diplôme : DIF en cours</p>
                  <SocialLinks />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Footer />
          </div>
        </section>
      </div>
    </div>
  );
}

export default EquipeComponent;
