import React from "react";
import Style from "./kkm.css";
import Navbar from "../../Components/Navbar";
import bannerImage1 from "../../images/banner/bannerMajid.JPG";
import Footer from "../../Components/Footer";

function Kickboxing() {
  return (
    <div>
      <div>
        <Navbar />
      </div>

      <div
        className="sample-header"
        style={{ backgroundImage: `url(${bannerImage1})` }}
      >
        <div className="sample-header-section">
          <h1>Libère la foudre que tu as dans tes poings</h1>
          <h3>le combat! c'est un bon moyen pour rapprocher les gens</h3>
        </div>
      </div>

      <div className="general">
        <div className="kkm-container">
          <header className="kkm-header">
            <h1>KICKBOXING</h1>
            <p>
              Les cours pour les enfants adolescents et les adultes à partir de
              6 ans
            </p>
          </header>

          <section className="kkm-section">
            <h2>Les Cours</h2>
            <p>
              Le kickboxing est un sport de combat qui combine des techniques de
              boxe anglaise et de coups de pied inspirés des arts martiaux
              notament du Karaté. Cette discipline offre de nombreux avantages
              pour le corps et l'esprit. Notre club propose du kickboxing
              japonais, également connu sous le nom de "K-1", est une forme de
              kickboxing qui a émergé au Japon dans les années 1990. Il se
              distingue par quelques caractéristiques spécifiques :
            </p>
            <p>
              Origines et Influence Fusion des Arts Martiaux : Le kickboxing
              japonais est issu de la fusion de diverses disciplines de combat,
              notamment le karaté, le muay thaï, la boxe anglaise et le
              taekwondo. Influence du K-1 : Le K-1, qui signifie "Karate-One", a
              été le promoteur majeur du kickboxing japonais. Il a popularisé ce
              sport à travers des tournois et des événements spectaculaires
              mettant en vedette des combattants de différentes disciplines.
            </p>
            <h2>Règles et Format</h2>
            <ul>
              <li>
                Combinaison de Coups de Poing et de Coups de Pied : Comme dans
                le kickboxing traditionnel, les combattants utilisent à la fois
                des coups de poing et des coups de pied, mais avec une plus
                grande liberté dans les techniques autorisées.
              </li>
              <li>
                Gants de Boxe : Les combattants portent généralement des gants
                de boxe pour protéger leurs mains lors des coups de poing.
              </li>
              <li>
                Utilisation des Genoux : Contrairement à certaines formes de
                kickboxing, le kickboxing japonais autorise souvent
                l'utilisation des genoux pour frapper l'adversaire, ce qui
                reflète l'influence du muay thaï.
              </li>
              <li>
                Règles Strictes : Les compétitions de kickboxing japonais ont
                généralement des règles strictes en ce qui concerne les coups
                autorisés, les prises et les techniques de soumission.
              </li>
            </ul>
          </section>
          <section className="kkm-benefits">
            <h2>Les Bénéfices de la Pratique du Karaté</h2>
            <ul>
              <li>Apprendre à se défendre</li>
              <li>Entretenir et développer sa forme physique</li>
              <li>Favoriser la coordination, l’équilibre et la mobilité</li>
              <li>
                Améliorer la souplesse de vos articulations et l’amplitude de
                vos mouvements
              </li>
              <li>
                Développer les capacités de concentration et de mémorisation
              </li>
              <li>Favoriser la maîtrise de ses émotions</li>
            </ul>
          </section>

          <section className="kkm-schedule">
            <h2>Les Horaires pour la section débutants</h2>
            <div className="schedule">
              <div className="day">
                <h3>Lundi</h3>
                <p>
                  18h - 19h : Kickboxing 7-10 ans (débutants) - dojo Paul
                  Langevin
                </p>
                <p>
                  19h - 20h : Kickboxing 11-15 ans (débutants) - dojo Paul
                  Langevin{" "}
                </p>
                <p>
                  20h - 22h : Kickboxing ados – adultes (débutants) - Salle
                  Edouard Vaillant
                </p>
              </div>

              <div className="day">
                <h3>Mercredi</h3>
                <p>
                  20h - 22h : Kickboxing ados – adultes (débutants) - Salle
                  Edouard Vaillant
                </p>
              </div>

              <div className="day">
                <h3>Jeudi</h3>
                <p>
                  18h - 19h : Kickboxing 7-10 ans (débutants) - dojo Paul
                  Langevin
                </p>
                <p>
                  19h - 20h : Kickboxing 11-15 ans (débutants) - dojo Paul
                  Langevin{" "}
                </p>
                <p>
                  20h - 22h : Kickboxing ados – adultes (débutants) - Salle
                  Edouard Vaillant
                </p>
              </div>

              <div className="day">
                <h3>Vendredi</h3>
                <p>
                  20h - 22h : Kickboxing ados – adultes (débutants) - Salle
                  Edouard Vaillant
                </p>
              </div>
            </div>
          </section>

          <section className="kkm-schedule">
            <h2>Les Horaires pour la section confirmés</h2>
            <div className="schedule">
              <div className="day">
                <h3>Mardi</h3>
                <p>
                  18h - 19h : Kickboxing 7-10 ans (avancés) - dojo Paul Langevin
                </p>
                <p>
                  19h - 20h : Kickboxing 11-15 ans (avancés) - dojo Paul
                  Langevin{" "}
                </p>
                <p>
                  20h - 22h : Kickboxing ados – adultes (avancés) - dojo Paul
                  Langevin
                </p>
              </div>

              <div className="day">
                <h3>Vendredi</h3>
                <p>
                  18h - 19h : Kickboxing 7-10 ans (avancés) - dojo Paul Langevin
                </p>
                <p>
                  19h - 20h : Kickboxing 11-15 ans (avancés) - dojo Paul
                  Langevin{" "}
                </p>
                <p>
                  20h - 22h : Kickboxing ados – adultes (avancés) - dojo Paul
                  Langevin
                </p>
              </div>
            </div>
          </section>

          <div
            className="sample-header"
            style={{ backgroundImage: `url(${bannerImage1})` }}
          ></div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Kickboxing;
