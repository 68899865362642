import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
//Accueil
import Accueil from "./Accueil";
//Club
import Equipe from "./pages/1-Club/Equipe";
import Presentation from "./pages/1-Club/Presentation";
import Horaires from "./pages/1-Club/Horaires";
//Disciplines
import Karate from "./pages/2-Disciplines/Karate";
import Kickboxing from "./pages/2-Disciplines/Kickboxing";
import LadiesKick from "./pages/2-Disciplines/LadiesKick";
import Mma from "./pages/2-Disciplines/Mma";
//Media
import Video from "./pages/3-Media/Video";
import Images from "./pages/3-Media/Images";
//Grade

function App() {
  return (
    <BrowserRouter>
      <Routes>
        //ACCUEIL
        <Route path="/" element={<Accueil />} />
        //CLUB
        <Route path="/pages/1-Club/Equipe" element={<Equipe />} />
        <Route path="/pages/1-Club/Presentation" element={<Presentation />} />
        <Route path="/pages/1-Club/Horaires" element={<Horaires />} />
        //DISCIPLINES
        <Route path="/pages/2-Disciplines/Karate" element={<Karate />} />
        <Route
          path="/pages/2-Disciplines/Kickboxing"
          element={<Kickboxing />}
        />
        <Route
          path="/pages/2-Disciplines/LadiesKick"
          element={<LadiesKick />}
        />
        <Route path="/pages/2-Disciplines/Mma" element={<Mma />} />
        //MEDIA
        <Route path="/pages/3-Media/Video" element={<Video />} />
        //BELT TEST
        <Route path="/pages/3-Media/Images" element={<Images />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
