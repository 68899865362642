import React from 'react'
import Style from "../Components/Footer.css";
import SocialLinks from './Equipe/SocialLinks';

function Footer() {
  return (
    <div>
<footer class="footer-distributed">

      <div class="footer-left">

        <h3>HOBUKAN<span>GENNEVILLIERS</span></h3>

        <p class="footer-links">
          <a href="#" class="link-1">Accueil</a>
          
          <a href="/pages/1-Club/Equipe">L'équipe</a>
        
          <a href="/pages/1-Club/Presentation">Présentation</a>
        
          <a href="/pages/1-Club/Horaires">Les horaires</a>
          
          <a href="/pages/3-Media/Video">Média</a>
          
          <a href="https://inscriptions.clicktoclub.com/index.php?r=&id=p6gp9BcKF1aayBD40H1cOkwBLSbiZxsV" target="_blank" rel="noopener noreferrer">Inscription</a>
        </p>

        <p class="footer-company-name">Site réalisé par Mohamed Senseï ©2024</p>
      </div>

      <div class="footer-center">

        <div>
          <i class="fa fa-map-marker"></i>
          <p><span>76 Rue de la Couture d'Auxerre</span>92230 Gennevilliers</p>
        </div>
        <div>
          <i class="fa fa-map-marker"></i>
          <p><span>20 Rue Julien Mocquard,</span>92230 Gennevilliers</p>
        </div>
        <div>
          <i class="fa fa-map-marker"></i>
          <p><span>Parc des Sports 201, avenue Laurent Cély</span>92230 Gennevilliers</p>
        </div>

        <div>
          <i class="fa fa-phone"></i>
          <p>+33 6 40 33 84 73</p>
        </div>

        <div>
          <i class="fa fa-envelope"></i>
          <p><a href="mailto:karatedo.hobukan92@gmail.com">karatedo.hobukan92@gmail.com</a></p>
        </div>

      </div>

      <div class="footer-right">

        <p class="footer-company-about">
          <span>A Propos de notre club</span>
          Créer en 2013 par des passionnées d'arts martiaux pour former et éduquer la jeunesse.
        </p>

        <div class="footer-icons">
            <SocialLinks />
        </div>

      </div>

    </footer>

    </div>
  )
}

export default Footer;
