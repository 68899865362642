import React from "react";
import Style from "./kkm.css";
import Navbar from "../../Components/Navbar";
import bannerImage1 from "../../images/banner/bannerKudo.jpg";
import Footer from "../../Components/Footer";

function Mma() {
  return (
    <div>
      <div>
        <Navbar />
      </div>

      <div
        className="sample-header"
        style={{ backgroundImage: `url(${bannerImage1})` }}
      >
        <div className="sample-header-section">
          <h1>Venez décourvir les arts martiaux mixtes</h1>
          <h3>un club de championnes et de champions</h3>
        </div>
      </div>

      <div className="general">
        <div className="kkm-container">
          <header className="kkm-header">
            <h1>MMA</h1>
            <p>
              Les cours de Kudo (MMA Japonais) à partir de 7 ans jusqu'à 15 ans
            </p>
            <p>Les cours de MMA à partir de 15 ans</p>
          </header>

          <section className="kkm-section">
            <h2>Les Cours</h2>

            <h3>LE KUDO (7 à 15 ans)</h3>
            <p>
              Le KUDO, également connu sous le nom de Daido Juku Karate, est un
              art martial japonais relativement moderne qui a été développé par
              Azuma Takashi. Il combine des techniques de combat au corps à
              corps, issues du karaté, du judo, du jiu-jitsu brésilien et du
              kickboxing. Le Kudo est également pratiqué comme un sport de
              compétition, avec des règles spécifiques pour assurer la sécurité
              des combattants (plastron, casque à bulle, gants, protèges-tibias
              et protège avant-bras)
            </p>
            <h3>LE MMA (+15 ans)</h3>
            <p>
              Le MMA, ou Mixed Martial Arts (arts martiaux mixtes), est un sport
              de combat qui met en compétition des combattants utilisant une
              variété de techniques de combat provenant de différentes
              disciplines, telles que la boxe, le jiu-jitsu brésilien, le judo,
              le muay thaï, le karaté, le taekwondo, et bien d'autres encore.
              Contrairement à d'autres sports de combat traditionnels où les
              compétiteurs sont souvent limités à l'utilisation de techniques
              spécifiques, le MMA permet à ses athlètes d'utiliser une gamme
              complète de techniques de combat, y compris les frappes debout,
              les projections, les soumissions au sol et le combat au sol.
            </p>
            <p>
              Les combats de MMA se déroulent généralement dans une cage
              octogonale, appelée "cage" ou "octogone", où les combattants
              s'affrontent sur plusieurs rounds, généralement de cinq minutes
              chacun. Les combats peuvent être remportés par knockout (KO),
              soumission, décision des juges ou abandon de l'adversaire.
            </p>
          </section>
          <section className="kkm-benefits">
            <h2>Les Bénéfices de la Pratique du Kudo et du MMA</h2>
            <ul>
              <li>
                <strong>Conditionnement physique amélioré :</strong> Les deux
                disciplines exigent un haut niveau de conditionnement physique,
                ce qui peut améliorer la force, l'endurance, la flexibilité et
                la coordination.
              </li>
              <li>
                <strong>
                  Développement de compétences de combat variées :
                </strong>{" "}
                En pratiquant le Kudo et le MMA, les participants apprennent
                différentes techniques de combat, ce qui peut les rendre plus
                polyvalents et efficaces dans diverses situations.
              </li>
              <li>
                <strong>Santé cardiovasculaire :</strong> Les entraînements
                intensifs de Kudo et de MMA, qui comprennent souvent des
                exercices aérobiques et anaérobiques, favorisent une bonne santé
                cardiovasculaire.
              </li>
              <li>
                <strong>Contrôle du poids :</strong> Les séances d'entraînement
                régulières peuvent aider à brûler des calories et à maintenir un
                poids corporel sain.
              </li>
              <li>
                <strong>Confiance accrue :</strong> La maîtrise des compétences
                de combat et l'amélioration de la condition physique peuvent
                renforcer la confiance en soi.
              </li>
              <li>
                La pratique régulière du Kudo et du MMA exige de la discipline,
                de la détermination et de la persévérance pour progresser et
                atteindre ses objectifs.
              </li>
              <li>
                <strong>Gestion du stress :</strong> L'entraînement physique
                intense peut aider à réduire le stress et à favoriser une
                meilleure gestion des émotions.
              </li>
              <li>
                <strong>Concentration :</strong> Les exercices de concentration
                et de focalisation nécessaires pendant les entraînements peuvent
                améliorer la capacité à se concentrer dans d'autres aspects de
                la vie quotidienne.
              </li>
              <li>
                <strong>Réduction de l'anxiété :</strong> L'exercice physique
                régulier est associé à une réduction de l'anxiété et à une
                amélioration de l'humeur.
              </li>
              <li>
                <strong>Esprit d'équipe :</strong> La pratique du Kudo et du MMA
                peut être réalisée en groupe, favorisant ainsi le développement
                de relations sociales et d'un esprit d'équipe.
              </li>
              <li>
                <strong>Dépassement de soi : </strong>Surmonter les défis
                physiques et mentaux pendant l'entraînement peut renforcer la
                résilience et la capacité à surmonter les obstacles dans
                d'autres domaines de la vie.
              </li>
            </ul>
          </section>
          <section className="kkm-schedule">
            <h2>Les Horaires</h2>
            <div className="schedule">
              <div className="day">
                <h3>Lundi</h3>
                <p>18h - 19h : Kudo enfants 7-10 ans</p>
                <p>19h - 20h : Kudo ados 11-15 ans</p>
                <p>20h - 22h : MMA + 15 ans</p>
              </div>

              <div className="day">
                <h3>Jeudi</h3>
                <p>18h - 19h : Kudo enfants 7-10 ans</p>
                <p>19h - 20h : Kudo ados 11-15 ans</p>
                <p>20h - 22h : MMA + 15 ans</p>
              </div>
            </div>
          </section>

          <div
            className="sample-header"
            style={{ backgroundImage: `url(${bannerImage1})` }}
          ></div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Mma;
