import React from 'react';
import style from '../Components/Header.css';

function Header() {
  return (
    <div id='main'>
        <div className='header-heading'>
          <h2>Réveil le guerrier qui est en toi</h2>
          <h1><span>HOBUKAN</span> c'est une famille</h1>
          <p className='details'>Prend confiance en toi et apprend à ne jamais renoncer</p>

          <div className='header-btns'>
            <a href="https://inscriptions.clicktoclub.com/index.php?r=&id=p6gp9BcKF1aayBD40H1cOkwBLSbiZxsV" target="_blank" className='header-btn'>INSCRIPTION</a>
          </div>

        </div>
    </div>
  )
}

export default Header;
