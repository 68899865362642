import React, { useState } from "react";
import Style from "../1-Club/Horaires.css";
import Navbar from "../../Components/Navbar";
import Image1 from "../../images/SVG/plus-solid.svg";
import Image2 from "../../images/SVG/minus-solid.svg";
import Footer from "../../Components/Footer";
import bannerImage1 from "../../images/banner/bannerYanis1.png";

const Dropdown = ({ title, schedules }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown1">
      <button className="btn-top" onClick={toggleDropDown}>
        <img src={Image1} alt="chevron plus" />
        <span>{title}</span>
      </button>
      <div className="bloc-links" style={{ height: isOpen ? "auto" : 0 }}>
        <ul>
          {schedules.map((schedule, index) => (
            <li key={index}>
              <div>
                <h6>{schedule.gym}</h6>
                <p>{schedule.time}</p>
                <a href={schedule.link}>En savoir +</a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const App = () => {
  const karate7_12 = [
    {
      gym: "Gymnase Edouard Vaillant",
      time: "Mercredi de 19h à 20h",
      link: "/pages/2-Disciplines/Karate",
    },
    {
      gym: "Gymnase Céline Dumerc",
      time: "Vendredi de 18h à 19h30",
      link: "/pages/2-Disciplines/Karate",
    },
  ];

  const kickboxing7_10 = [
    {
      gym: "Gymnase Paul Langevin (débutants)",
      time: "lundi et jeudi de 18h à 19h",
      link: "/pages/2-Disciplines/Kickboxing",
    },
    {
      gym: "Gymnase Paul Langevin (confirmés)",
      time: "mardi et vendredi de 18h à 19h",
      link: "/pages/2-Disciplines/Kickboxing",
    },
  ];

  const kickboxing11_15 = [
    {
      gym: "Gymnase Paul Langevin (débutants)",
      time: "lundi et jeudi de 19h à 20h",
      link: "/pages/2-Disciplines/Kickboxing",
    },
    {
      gym: "Gymnase Paul Langevin (confirmés)",
      time: "mardi et vendredi de 19h à 20h",
      link: "/pages/2-Disciplines/Kickboxing",
    },
  ];

  const kickboxingAdults = [
    {
      gym: "Gymnase Edouard Vaillant (débutants)",
      time: "Mercredi et vendredi de 20h à 22h",
      link: "/pages/2-Disciplines/Kickboxing",
    },
    {
      gym: "Gymnase Paul Langevin (confirmés)",
      time: "mardi et vendredi de 20h à 22h",
      link: "/pages/2-Disciplines/Kickboxing",
    },
  ];

  const mmaBudo7_10 = [
    {
      gym: "Gymnase Céline Dumerc",
      time: "lundi et jeudi de 18h à 19h",
      link: "/pages/2-Disciplines/Mma",
    },
  ];

  const mmaBudo11_15 = [
    {
      gym: "Gymnase Céline Dumerc",
      time: "lundi et jeudi de 19h à 20h",
      link: "/pages/2-Disciplines/Mma",
    },
  ];

  const mmaBudoAdults = [
    {
      gym: "Gymnase Céline Dumerc",
      time: "mardi et jeudi de 20h à 22h",
      link: "/pages/2-Disciplines/Mma",
    },
  ];

  const kickFightLadies = [
    {
      gym: "Gymnase Paul Langevin",
      time: "Lundi et jeudi de 20h à 22h",
      link: "#",
    },
  ];

  return (
    <div>
      <div>
        <Navbar />
      </div>

      <div
        className="sample-header"
        style={{ backgroundImage: `url(${bannerImage1})` }}
      >
        <div className="sample-header-section">
          <h1>LES HORAIRES</h1>
          <div className="header-btns">
            <a
              href="https://inscriptions.clicktoclub.com/index.php?r=&id=p6gp9BcKF1aayBD40H1cOkwBLSbiZxsV"
              target="_blank"
              className="header-btn"
            >
              INSCRIPTION
            </a>
          </div>
        </div>
      </div>

      <div className="general">
        <main>
          <div className="les_horaires">
            <div className="horaires_karate">
              <h2>KARATE</h2>
              <Dropdown title="Karaté 7-12 ans" schedules={karate7_12} />
            </div>

            <div className="horaires_kickboxing">
              <h2>KICKBOXING</h2>
              <Dropdown
                title="Kickboxing 7-10 ans"
                schedules={kickboxing7_10}
              />
              <Dropdown
                title="Kickboxing 11-15 ans"
                schedules={kickboxing11_15}
              />
              <Dropdown
                title="Kickboxing adultes"
                schedules={kickboxingAdults}
              />
            </div>

            <div className="horaires_mma_budo">
              <h2>MMA BUDO</h2>
              <Dropdown title="MMA Budo 7-10 ans" schedules={mmaBudo7_10} />
              <Dropdown title="MMA Budo 11-15 ans" schedules={mmaBudo11_15} />
              <Dropdown title="MMA Budo adultes" schedules={mmaBudoAdults} />
            </div>

            <div className="Kick_Fight_Ladies">
              <h2>LADIES FIGHT</h2>
              <Dropdown title="Kick Fight Ladies" schedules={kickFightLadies} />
            </div>
          </div>
        </main>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
