import React from "react";
import Navbar from "../../Components/Navbar";
import Style from "../1-Club/Presentation.css";
import Logo from "../../images/logo/logo2024.png";
import Maps from "../../images/club/Gennevilliers-Maps.png";
import image1 from "../../images/club/Mohamed_Belalia_Sanshin.PNG";
import image2 from "../../images/club/Mohamed_Belalia_Coach.PNG";
import VideoDemo from "../../Components/React-player/Demo";
import Footer from "../../Components/Footer";

function Presentation() {
  return (
    <div>
      <div>
        <Navbar />
      </div>

      <>
        <div className="general" style={{ minwidth: "1100px" }}>
          <div className="le_projet_d_un_passionne">
            <div className="projet_text">
              <h3> le projet d'un passionné</h3>
              <p>
                <strong>
                  L’histoire commence en 1992, lorsque Mohamed BELALIA, 6 fois
                  champion de France de Karaté dans plusieurs style et double
                  champion d’Europe de karaté, décide de fonder sa propre école
                  : KARATEDO HOBUKAN GENNEVILLIERS Avec plus de 25 ans
                  d’expertise.
                  <br />
                  <br />
                  Mohamed BELALIA 3e dan de Karaté Goju Ryu Hobukan, 3e dan de
                  Karaté Koshiki et 3e Dan en karaté Contact s’est entouré d’une
                  équipe de passionnés qui vous accompagne dans un cadre
                  professionnel et convivial. <br />
                  <br />
                  Au travers de ses enseignements, le club vous propose de
                  découvrir 3 disciplines: Le Goju-Ryu, le Kyoku et le
                  Kickboxing japonais
                </strong>
              </p>
            </div>
            <div className="projet_vidéo">
              <VideoDemo />
            </div>
          </div>
          <div className="situation_geo">
            <div className="maps">
              <img src={Maps} alt="Maps de la ville de Gennevilliers" />
            </div>
            <div className="maps_text">
              <h3>des salles d'entrainements à proximité </h3>
              <p>
                <strong>
                  Idéalement situé dans plusieurs quartier à Gennevilliers,
                  Hobukan vous accueille pendant toute la saison sportive pour
                  découvrir nos disciplines accessibles à toute la famille à
                  partir de 6 ans.
                </strong>
              </p>
              <h3>les complexes sportifs</h3>
              <p>
                <strong>
                  Les professeurs vous donnent rendez-vous dans les complexes
                  suivants
                </strong>
              </p>
              <div>
                <ul>
                  <li>Dojo Paul Langevin</li>
                  <li>Dojo Céline Dumerc</li>
                  <li>Salle de danse Edouard Vaillant</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="raisons_logo">
            <img src={Logo}></img>
          </div>

          <div className="raisons">
            <div className="r_descriptif">
              <h3>LES 5 BONNES RAISONS DE CHOISIR LE CLUB HOBUKAN</h3>
              <p>
                <strong>
                  Vous envisagez de pratiquer un art martial, un sport de
                  self-défense ou retrouver la forme mais vous ne savez pas
                  encore quel club choisir à Paris ? Débutant, confirmé, seul,
                  en famille ou entre amis, découvrez 5 bonnes raisons de venir
                  rejoindre l’Institut des Arts Martiaux.
                </strong>
              </p>
            </div>
          </div>

          <div className="cinq_raisons">
            <h4>UN CLUB OÙ ON S’OCCUPE DE VOUS :</h4>
            <p>
              <strong>
                Que vous soyez en cours particulier ou en cours collectif, nos
                professeurs vous apportent la même attention. Notre métier est
                de vous conseiller, de vous encourager et de vous accompagner à
                chaque étape de votre progression, bonne humeur incluse.
              </strong>
            </p>
            <h4>DU SPORT POUR TOUTE LA FAMILLE :</h4>
            <p>
              <strong>
                Les enfants au karaté ou au kickboxing, maman au Kick Fight
                Ladies et papa au Kickboxing ou MMA, l’Academy Hobukan
                Gennevilliers c’est la garantie de trouver la discipline qui
                vous correspond.
              </strong>
            </p>
            <h4>UN CLUB DE COMPETITEURS</h4>
            <p>
              <strong>
                Notre équipe est à votre service pour vous accompagner dans
                votre projet sportif aussi bien dans le kickboxing, le MMA et le
                karaté. Nous crérons ensemble votrplan de carrière.
              </strong>
            </p>
            <h4>UNE ÉQUIPE D’EXPERTS DIPLÔMÉS :</h4>
            <p>
              <strong>
                Les professeurs de l’Academy Hobukan sont certifiés par un
                diplôme fédéral ou d’état. Tous ont suivi une formation
                technique et pédagogique complémentaire avec le directeur
                technique Senseï MohamedBelalia diplômé d’état, 4ième dan de
                karaté{" "}
              </strong>
            </p>
            <h4>UN CLUB FACILE D’ACCÈS :</h4>
            <p>
              <strong>
                Notre salle d’entrainement est idéalement située dans le 13e
                arrondissement de Paris, à quelques pas du Centre Commercial
                Italie 2.
              </strong>
            </p>
          </div>
          <div className="club_myt">
            <div className="club_myt_text">
              <h3>UN CLUB MYTHIQUE ET UNE ÉQUIPE DIPLÔMÉE</h3>
              <p>
                <strong>
                  La Team Mohamed Belalia est composée d’enseignants qualifiés
                  et dynamiques pour vous permettre de progresser dans les
                  meilleures conditions et atteindre vos objectifs.
                </strong>
              </p>
              <p>
                <strong>
                  En plus des cours collectifs, le club Hobukan offre la
                  possibilité à ses adhérents de souscrire à des cours de
                  coaching personnalisés avec le moniteur ou la monitrice de
                  votre choix. Une prestation sur mesure pour vous guider et
                  accélérer votre progression.
                </strong>
              </p>
              <p>
                <strong>
                  Des événements réguliers viennent rythmer les saisons
                  sportives et parfaire votre progression : stages thématiques
                  et de découverte, compétitions, démonstrations, festivités …
                </strong>
              </p>
              <p>
                <strong>
                  Grâce à un programme pédagogique de haute qualité, l’academy
                  Hobukan a formé plus de 5 ceintures noires du 1er au 3e dan,
                  de très nombreux champions et enseignants diplômés.
                </strong>
              </p>
            </div>
            <div className="club_myt_photo">
              <img src={image1} alt="{Mohamed Belalia position sanshin}" />
            </div>
          </div>
          <div className="certification">
            <div className="certif_photo">
              <img src={image2} alt="Mohamed Belalia Coach" />
            </div>
            <div className="certif_text">
              <h3>CERTIFICATION, AGRÉMENT ET LABEL</h3>
              <p>
                <strong>
                  L’academy Hobukan est une association sportive d’excellence,
                  certifiée par de nombreux organismes d’état.
                </strong>
              </p>
              <ul>
                <li>Affiliée à la Fédération Française de Karaté (FFK)</li>
                <li>Affiliée à Kudo International Fédération</li>
                <li>Affiliée au comité Français de Karaté Koshiki</li>
                <li>Agrément Jeunesse et Sport</li>
                <li>
                  Agrément de service civique et de volontariat associatif
                </li>
                <li>Labellisée Karaté Santé depuis 2021 par la FFK</li>
              </ul>
            </div>
          </div>
        </div>
      </>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Presentation;
