import React from 'react';
import aboutimage from '../../images/about/about-3.png';

function About() {
  return (
    <div className='general'>
    <div id='about'>
        <div className='about-image'>
            <img src={aboutimage} alt="image en savoir plus" />
        </div>
        <div className='about-text'>
            <h1>EN APPENDRE PLUS SUR NOTRE CLUB</h1>
            <p>Au sein de notre club d'arts martiaux, nous valorisons non seulement la pratique et la maîtrise des techniques martiales, mais également l'échange et le partage avec d'autres passionnés. Nous ne nous contentons pas de rester renfermés sur nous-mêmes ; notre engagement s'étend bien au-delà des murs de notre dojo.</p>

            <p>Nous entretenons des relations étroites avec divers clubs en France et à l'étranger, ce qui nous permet d'organiser régulièrement des stages, des échanges et des compétitions. Ces interactions enrichissent nos pratiquants par la découverte de nouvelles approches et techniques, tout en renforçant les liens de camaraderie et d'entraide au sein de la grande famille des arts martiaux.</p>

            <p>En participant à ces échanges internationaux, nos membres ont l'opportunité unique de s'immerger dans différentes cultures martiales, favorisant ainsi une ouverture d'esprit et une compréhension globale de notre discipline.</p>

            <p>Rejoignez-nous pour vivre une expérience martiale riche et diversifiée, où le respect, le partage et l'apprentissage mutuel sont au cœur de notre philosophie.</p>
            <button><a href="/pages/1-Club/Presentation" className='pr-btn'>EN SAVOIR PLUS</a></button>
        </div>
    </div>
    </div>
  )
}

export default About;
