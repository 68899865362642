import React from 'react'
import {Link} from 'react-scroll';
import Navbar_css from "./Navbar.css";
import logo from '../images/logo/logo2024.png';

function Navbar() {
  return (
 
    <div className='navbar-drop'>
        <Link to='/' className='logo'>
            <img src={logo} alt="logo du club" />
        </Link>

        <input type="checkbox" id='menu-bar'/>
        <label htmlFor="menu-bar">MENU</label>

        <nav className='navbar'>
            <ul>
                <li><a href="/"><i className="fa-solid fa-house" style={{ color: "#ffffff" }} /></a></li>
                <li className="dropdown"><a href="#">LE CLUB +</a>
                <ul className="dropdown-content">
                  <li><a href="/pages/1-Club/Presentation">PRESENTATION</a></li>
                  <li><a href="/pages/1-Club/Equipe">EQUIPE</a></li>
                  <li><a href="/pages/1-Club/Horaires">HORAIRES</a></li>
                </ul>
                </li>
                <li className="dropdown"><a href="#">LES DISCIPLINES +</a>
                <ul className="dropdown-content">
                  <li><a href="/pages/2-Disciplines/Kickboxing">KICKBOXING</a></li>
                  <li><a href="/pages/2-Disciplines/Karate">KARATE</a></li>
                  <li><a href="/pages/2-Disciplines/LadiesKick">LADIES KICK</a></li>
                </ul>
                </li>
                <li className="dropdown"><a href="">MEDIA +</a>
                <ul className="dropdown-content">
                  <li><a href="/pages/3-Media/Video">VIDEOS</a></li>
                  <li><a href="/pages/3-Media/Images">IMAGES</a></li>
                </ul>
                </li>
                <li className="dropdown"><a href="https://inscriptions.clicktoclub.com/index.php?r=&id=p6gp9BcKF1aayBD40H1cOkwBLSbiZxsV" target="_blank" rel="noopener noreferrer">INSCRIPTION</a></li>
                
                

            </ul>
        </nav>
      
    </div>
  )
}

export default Navbar;
