import React from 'react';

function Offer() {
  return (

        <div id='offer'>
          <div className='pr-heading'>
            <h1>DES <span>PROFESSEURS</span> DIPLOMES ET <span>EXPERIMENTES</span></h1>
            <p className='details'>Découvrez ici nos différentes offres karaté, Kickboxing, MMA Budo et Kick Fight Ladies</p>
            <div className='pr-btns'>
              <a href="/pages/1-Club/Equipe" className='pr-btn'>L'EQUIPE</a>
            </div>
          </div>
        </div>

  
  )
}

export default Offer;
