import React from "react";
import Navbar from "./Components/Navbar";
import Header from "./Components/Header";
import Feature from "./Components/Accueil/Feature";
import Offer from "./Components/Accueil/Offer";
import About from "./Components/Accueil/About";
import Contact from "./Components/Accueil/Contact";
import Footer from "./Components/Footer";
import Navbar_dropdown from "./Components/Navbar";

function Accueil() {
  return (
    <div className="general">
      <div className="App">
        <Navbar_dropdown />
        <Header />
        <Feature />
        <Offer />
        <About />
        <Footer />
      </div>
    </div>
  );
}

export default Accueil;
