import React from "react";
import "../2-Disciplines/kkm.css"; // Corrected import path for CSS
import Navbar from "../../Components/Navbar"; // Assuming this path is correct
import bannerImage1 from "../../images/banner/MoradYanis.JPG";
import Footer from "../../Components/Footer";

function Karate() {
  return (
    <div>
      <Navbar />

      <div
        className="sample-header"
        style={{ backgroundImage: `url(${bannerImage1})` }}
      >
        <div className="sample-header-section">
          <h1>Prend confiance en toi</h1>
          <h3>libère ton potentiel</h3>
        </div>
      </div>

      <div className="general">
        <div className="kkm-container">
          <header className="kkm-header">
            <h1>KARATE</h1>
            <p>
              Les cours pour les enfants adolescents et les adultes à partir de
              6 ans
            </p>
          </header>

          <section className="kkm-section">
            <h2>Les Cours</h2>
            <p>
              Il n’est jamais trop tard pour commencer le karaté, et l’on n’est
              jamais assez âgé pour arrêter. La section ados et adultes de
              l’Institut des Arts Martiaux vous accueille à partir de 13 ans. Le
              maître Serge Serfati accompagné de ses assistants proposent un
              programme pédagogique en fonction de votre âge, de votre
              morphologie et de vos capacités physiques.
            </p>
            <p>
              La philosophie des cours est basée sur l’esprit des arts martiaux
              et des sports de combat. L’ensemble des dimensions du karaté sont
              enseignées : kumite, combat sportif, kata, karaté défense...
            </p>
          </section>

          <section className="kkm-benefits">
            <h2>Les Bénéfices de la Pratique du Karaté</h2>
            <ul>
              <li>Apprendre à se défendre</li>
              <li>Entretenir et développer sa forme physique</li>
              <li>Favoriser la coordination, l’équilibre et la mobilité</li>
              <li>
                Améliorer la souplesse de vos articulations et l’amplitude de
                vos mouvements
              </li>
              <li>
                Développer les capacités de concentration et de mémorisation
              </li>
              <li>Favoriser la maîtrise de ses émotions</li>
            </ul>
          </section>

          <section className="kkm-schedule">
            <h2>Les Horaires avec Sempai Rayane</h2>
            <div className="schedule">
              <div className="day">
                <h3>Mercredi</h3>
                <p>19h00 - 20h : Karaté 7-12 ans (Gymnase Edouard Vaillant)</p>
              </div>
              <div className="day">
                <h3>Vendredi</h3>
                <p>18h - 20h00 : Karaté 7-12 ans (Gymnase Céline Dumerc)</p>
              </div>
            </div>
          </section>

          <section className="kkm-schedule">
            <h2>Les Horaires avec Sensei Mohamed</h2>
            <div className="schedule">
              <div className="day">
                <h3>Lundi</h3>
                <p>
                  18h - 19h : Kickboxing 7-10 ans (avancés) - dojo Céline Dumerc{" "}
                </p>
                <p>
                  19h - 20h : Kickboxing 11-15 ans (avancés) - dojo Céline
                  Dumerc{" "}
                </p>
                <p>
                  20h - 22h : Kickboxing ados – adultes (avancés) - dojo Céline
                  Dumerc
                </p>
              </div>

              <div className="day">
                <h3>Jeudi</h3>
                <p>
                  18h - 19h : Kickboxing 7-10 ans (avancés) - dojo Céline Dumerc{" "}
                </p>
                <p>
                  19h - 20h : Kickboxing 11-15 ans (avancés) - dojo Céline
                  Dumerc{" "}
                </p>
                <p>
                  20h - 22h : Kickboxing ados – adultes (avancés) - dojo Céline
                  Dumerc
                </p>
              </div>
            </div>
          </section>

          <div
            className="sample-header"
            style={{ backgroundImage: `url(${bannerImage1})` }}
          ></div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Karate;
