import React from "react";
import Style from "./kkm.css";
import Navbar from "../../Components/Navbar";
import bannerImage1 from "../../images/banner/ZainebKick.jpg";
import Footer from "../../Components/Footer";

function LadiesKick() {
  return (
    <div>
      <div>
        <Navbar />
      </div>

      <div
        className="sample-header"
        style={{ backgroundImage: `url(${bannerImage1})` }}
      >
        <div className="sample-header-section">
          <h1>Mesdames, libérer votre potentiel</h1>
          <h3>
            Venez vous exprimer dans une discipline qui vous est complétement
            adaptée
          </h3>
        </div>
      </div>

      <div className="general">
        <div className="kkm-container">
          <header className="kkm-header">
            <h1>LADIES KICKBOXING</h1>
            <p>
              Cours pour les adolescents et adultes féminine (à partir de 16
              ans)
            </p>
          </header>

          <section className="kkm-section">
            <h2>Les Cours</h2>
            <p>
              Le kickboxing est un sport de combat qui combine des techniques de
              boxe anglaise et de coups de pied inspirés des arts martiaux
              notament du Karaté. Cette discipline offre de nombreux avantages
              pour le corps et l'esprit. Notre club propose du kickboxing
              japonais, également connu sous le nom de "K-1", est une forme de
              kickboxing qui a émergé au Japon dans les années 1990. Il se
              distingue par quelques caractéristiques spécifiques :
            </p>
            <p>
              Origines et Influence Fusion des Arts Martiaux : Le kickboxing
              japonais est issu de la fusion de diverses disciplines de combat,
              notamment le karaté, le muay thaï, la boxe anglaise et le
              taekwondo. Influence du K-1 : Le K-1, qui signifie "Karate-One", a
              été le promoteur majeur du kickboxing japonais. Il a popularisé ce
              sport à travers des tournois et des événements spectaculaires
              mettant en vedette des combattants de différentes disciplines.
            </p>
            <h2>Règles et Format</h2>
            <ul>
              <li>
                Combinaison de Coups de Poing et de Coups de Pied : Comme dans
                le kickboxing traditionnel, les combattants utilisent à la fois
                des coups de poing et des coups de pied, mais avec une plus
                grande liberté dans les techniques autorisées.
              </li>
              <li>
                Gants de Boxe : Les combattants portent généralement des gants
                de boxe pour protéger leurs mains lors des coups de poing.
              </li>
              <li>
                Utilisation des Genoux : Contrairement à certaines formes de
                kickboxing, le kickboxing japonais autorise souvent
                l'utilisation des genoux pour frapper l'adversaire, ce qui
                reflète l'influence du muay thaï.
              </li>
              <li>
                Règles Strictes : Les compétitions de kickboxing japonais ont
                généralement des règles strictes en ce qui concerne les coups
                autorisés, les prises et les techniques de soumission.
              </li>
            </ul>
          </section>
          <section className="kkm-benefits">
            <h2>Pourquoi rejoindre notre section féminine ?</h2>
            <ul>
              <li>
                Environnement sécurisé et convivial : Un espace où les femmes
                peuvent s'entraîner en toute confiance, entourées d'autres
                pratiquantes
              </li>
              <li>
                Instructeurs expérimentés : Des coachs spécialisés dans
                l'entraînement féminin, prêts à vous guider et à vous motiver à
                chaque étape
              </li>
              <li>
                Développement de la confiance en soi : Apprenez à maîtriser
                votre corps et votre esprit, et gagnez en assurance au fil des
                séances
              </li>
              <li>
                Condition physique et bien-être : Améliorez votre force, votre
                endurance, et votre souplesse tout en vous défoulant
              </li>
              <li>
                Cours adaptés à tous les niveaux : Que vous soyez débutante ou
                expérimentée, nos cours sont conçus pour s'adapter à votre
                rythme et à vos objectifs.
              </li>
              <li>Favoriser la maîtrise de ses émotions</li>
            </ul>
          </section>

          <section className="kkm-benefits">
            <h2>Ce que vous apprendrez</h2>
            <li>Techniques de frappe (poings, pieds)</li>
            <li>Défenses personnelles</li>
            <li>Combinaisons et enchaînements</li>
            <li>Amélioration de la condition physique générale</li>
            <li>Gestion du stress et maîtrise de soi</li>
          </section>

          <section className="kkm-schedule">
            <h2>Les Horaires</h2>
            <div className="schedule">
              <div className="day">
                <h3>Lundi</h3>
                <p>
                  20h - 22h : Kickboxing ados et adultes (+15 ans) - dojo Paul
                  Langevin
                </p>
              </div>

              <div className="day">
                <h3>Jeudi</h3>
                <p>
                  20h - 22h : Kickboxing ados et adultes (+15 ans) - dojo Paul
                  Langevin
                </p>
              </div>
            </div>
          </section>

          <div
            className="sample-header"
            style={{ backgroundImage: `url(${bannerImage1})` }}
          ></div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default LadiesKick;
